<template>
  <transition name="modal">
    <div class="modal-mask" v-show="condition">
      <div class="modal-container" v-if="modaltype === 'delete'">
        <div class="modal-header">
          <b-icon @click="closeModal()" icon="x"/>
        </div>
        <div class="deleted-window">
          <div class="modal-header-title">
            <h6>
              Are you sure to delete this image?
            </h6>
          </div>
          <div class="modal-footer">
            <sbmt-btn
              :buttonText="$t('YES', currentSelectedLanguage)"
              @buttonEventEmit="submitModal('delete')"
            />
            <sbmt-btn
              :buttonText="$t('NO', currentSelectedLanguage)"
              @buttonEventEmit="closeModal()"
            />
          </div>
        </div>
      </div>
      <div class="modal-container" v-if="modaltype === 'deletePatient'">
        <div class="modal-header">
          <b-icon @click="closeModal()" icon="x"/>
        </div>
        <div class="deleted-window">
          <div class="modal-header-title">
            <h6>
              Are you sure you want
              to delete this patient?
            </h6>
          </div>
          <div class="modal-footer">
            <sbmt-btn
              :buttonText="$t('YES', currentSelectedLanguage)"
              @buttonEventEmit="submitModal('deletePatient')"
            />
            <sbmt-btn
              :buttonText="$t('NO', currentSelectedLanguage)"
              @buttonEventEmit="closeModal()"
            />
          </div>
        </div>
      </div>
      <div class="modal-container" v-if="modaltype === 'patientId'">
        <div class="modal-header">
          <b-icon @click="closeModal()" icon="x"/>
        </div>
        <div class="universal-window">
          <div class="modal-header-title">
            <h6>
              Edit Patients Clinic ID
            </h6>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="observerEditForm" v-slot="{ invalid }">
              <simple-input
                ref="e"
                name-input="Edit Id"
                @keyUpInputEvent="patientClinicId"
                :restrict-special-symbols="true"
                :classArray="['mb-2', 'universal-input']"
                :input-value="initialData.PatientInfo.ClinicAccountId"
                :input-rule="`required|max:50`"
                data-vv-as="Some other field"
              />
              <p v-if="patientUpdatedDataError" class="invalid-feedback d-block text-center">
                {{
                  patientUpdatedDataError
                }}
              </p>
              <div class="modal-footer">
                <sbmt-btn
                  :buttonText="$t('Save', currentSelectedLanguage)"
                  @buttonEventEmit="submitModal('editid')"
                  :button-state="invalid"
                />
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="modal-container" v-if="modaltype === 'editBiopsy'">
        <div class="modal-header">
          <b-icon @click="closeModal()" icon="x"/>
        </div>
        <div class="universal-window">
          <div class="modal-header-title">
            <h6>
              Biopsy data
            </h6>
          </div>
          <div class="modal-body">
            <label>
              {{ $t('Biopsy result', currentSelectedLanguage) }}
            </label>
            <dropdown
              labelTextDisplayProp="label"
              :placeHolderList="$t('Biopsy result', currentSelectedLanguage)"
              :drop-down-input-no-data="$t('NoBiopsyRelut', currentSelectedLanguage)"
              :selectedArray="biopsyResult"
              :searc-ability-props="false"
              @inputSelected="(value)=>selectValue('biopsyResult',value)"
              :current-value="BiopsyComp"
              class="special-input"
            />
            <label>
              {{ $t('Tumorsize', currentSelectedLanguage) }}
            </label>
            <dropdown
              labelTextDisplayProp="label"
              :placeHolderList="$t('Tumorsize', currentSelectedLanguage)"
              :drop-down-input-no-data="$t('NoTumorSizeRelut', currentSelectedLanguage)"
              :selectedArray="tumorSize"
              :searc-ability-props="false"
              @inputSelected="(value)=>selectValue('tumorSize',value)"
              :current-value="TumorSizeComp"
              class="special-input"
            />
            <label>
              {{ $t('Tumorsite', currentSelectedLanguage) }}
            </label>
            <dropdown
              labelTextDisplayProp="label"
              :placeHolderList="$t('Tumorsite', currentSelectedLanguage)"
              :drop-down-input-no-data="$t('NoTumorSiteRelut', currentSelectedLanguage)"
              :selectedArray="tumorSide"
              :searc-ability-props="false"
              @inputSelected="(value)=>selectValue('tumorSite', value)"
              :current-value="TumorSiteComp"
              class="special-input"
            />
            <label>
              {{ $t('Tumor location', currentSelectedLanguage) }}
            </label>
            <dropdown
              labelTextDisplayProp="label"
              :placeHolderList="$t('Tumor location', currentSelectedLanguage)"
              :drop-down-input-no-data="$t('NoLocationRelut', currentSelectedLanguage)"
              :selectedArray="tumorLocation"
              :searc-ability-props="false"
              @inputSelected="(value)=>selectValue('tumorLocation', value)"
              :current-value="TumorLocationComp"
              class="special-input"
            />
          </div>
          <div class="modal-footer">
            <sbmt-btn
              :buttonText="$t('Save', currentSelectedLanguage)"
              @buttonEventEmit="submitModal('editBiopsy')"
              :button-state="buttonComputedState"
            />
          </div>
        </div>
      </div>
      <div class="modal-container" v-if="modaltype === 'editRelated'">
        <div class="modal-header">
          <b-icon @click="closeModal()" icon="x"/>
        </div>
        <div class="universal-window">
          <div class="modal-header-title">
            <h6>
              Visit data
            </h6>
          </div>
          <div class="modal-body">
            <label>
              {{ $t('Palpable mass in the breast', currentSelectedLanguage) }}
            </label>
            <dropdown
              labelTextDisplayProp="label"
              :placeHolderList="$t('Palpable mass in the breast', currentSelectedLanguage)"
              :drop-down-input-no-data="$t('NoPalpableResult', currentSelectedLanguage)"
              :searc-ability-props="false"
              :selectedArray="palpable"
              @inputSelected="(value)=>selectValue('palpableMassInTheBreast',value)"
              :current-value="PalpableComp"
              class="special-input"
            />
            <label>
              {{ $t('Pain in the breast', currentSelectedLanguage) }}
            </label>
            <dropdown
              labelTextDisplayProp="label"
              :placeHolderList="$t('Pain in the breast', currentSelectedLanguage)"
              :drop-down-input-no-data="$t('NoPainResult', currentSelectedLanguage)"
              :searc-ability-props="false"
              :selectedArray="pain"
              @inputSelected="(value)=>selectValue('painInTheBreast',value)"
              :current-value="PainComp"
              class="special-input"
            />
            <label>
              {{ $t('MenstrualCycle', currentSelectedLanguage) }}
            </label>
            <dropdown
              labelTextDisplayProp="label"
              :placeHolderList="$t('MenstrualCycle', currentSelectedLanguage)"
              :drop-down-input-no-data="$t('NoMenstrualCycle', currentSelectedLanguage)"
              :searc-ability-props="false"
              :selectedArray="menstrualCycle"
              @inputSelected="(value)=>selectValue('menstrualCycle',value)"
              :current-value="menstrualCycleComp"
              class="special-input"
            />
            <simple-input
              v-if="updatedData?updatedData.menstrualCycle===menstrualCycleEnum.premenopausal.value:false"
              :input-value="menstrualCycleDays"/>
          </div>
          <div class="modal-footer">
            <sbmt-btn
              :buttonText="$t('Save', currentSelectedLanguage)"
              @buttonEventEmit="submitModal('submitRelated')"
              :button-state="buttonComputedState"
            />
          </div>
        </div>
      </div>
      <div class="modal-container" v-if="modaltype === 'editBiRads'">
        <div class="modal-header">
          <b-icon @click="closeModal()" icon="x"/>
        </div>
        <div class="universal-window">
          <div class="modal-header-title">
            <h6>
              BI-RADS data
            </h6>
          </div>
          <div class="modal-body">
            <label>
              {{ $t('Left', currentSelectedLanguage) }}
            </label>
            <dropdown
              labelTextDisplayProp="label"
              :placeHolderList="$t('Left', currentSelectedLanguage)"
              :drop-down-input-no-data="$t('NoBiRadsData', currentSelectedLanguage)"
              :searc-ability-props="false"
              :selectedArray="biRads"
              @inputSelected="(value)=>selectValue('biRadsLeft',value)"
              :current-value="BiRadsLeftComp"
              class="special-input"
            />
            <label>
              {{ $t('Right', currentSelectedLanguage) }}
            </label>
            <dropdown
              labelTextDisplayProp="label"
              :placeHolderList="$t('Right', currentSelectedLanguage)"
              :drop-down-input-no-data="$t('NoBiRadsData', currentSelectedLanguage)"
              :searc-ability-props="false"
              :selectedArray="biRads"
              @inputSelected="(value)=>selectValue('biRadsRight',value)"
              :current-value="BiRadsRightComp"
              class="special-input"
            />
          </div>
          <div class="modal-footer">
            <sbmt-btn
              :buttonText="$t('Save', currentSelectedLanguage)"
              @buttonEventEmit="submitModal('editBiRads')"
              :button-state="buttonComputedState"
            />
          </div>
        </div>
      </div>
      <div class="modal-container" v-if="modaltype === 'setDateOfBirth'">
        <div class="modal-header">
          <b-icon @click="closeModal()" icon="x"/>
        </div>
        <div class="universal-window">
          <div class="modal-header-title">
            <h6>
              Date of Birth
            </h6>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="observerEditForm" v-slot="{ invalid }">
              <simpleInput
                class="form-login-inp-wrp"
                input-rule="required"
                name-input="date of birth"
                input-type="date"
                :input-value="initialData.PatientInfo.DateOfBirth"
                :classArray="['mb-2', 'universal-input']"
                :placeholderText="$t('dd-mm-yyyy', currentSelectedLanguage)"
                @keyUpInputEvent="setLocalDateOfBirth"
              />
              <p v-if="patientUpdatedDataError" class="invalid-feedback d-block text-center">
                {{
                  patientUpdatedDataError
                }}
              </p>
              <div class="modal-footer">
                <sbmt-btn
                  :buttonText="$t('Save', currentSelectedLanguage)"
                  @buttonEventEmit="submitModal('setDateOfBirth')"
                  :button-state="invalid"
                />
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="modal-container" v-if="modaltype === 'deleteDocument'">
        <div class="modal-header">
          <b-icon @click="closeModal()" icon="x"/>
        </div>
        <div class="deleted-window">
          <div class="modal-header-title">
            <h6>
              Are you sure you want
              to delete this document?
            </h6>
          </div>
          <div class="modal-footer">
            <sbmt-btn
              :buttonText="$t('YES', currentSelectedLanguage)"
              @buttonEventEmit="submitModal('deleteDocument')"
            />
            <sbmt-btn
              :buttonText="$t('NO', currentSelectedLanguage)"
              @buttonEventEmit="closeModal()"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import sbmtBtn from '../atoms/button.vue';
import simpleInput from '../atoms/input.vue';
import {REMOVE_MODAL_ACTION} from '../../store/index';
import {
  EDIT_PATIENT_ID,
  DELETE_PATIENT,
  REQUEST_BIOPSY_DATA_CHANGE,
  EDIT_ADDITIONAL_DATA_CHANGE,
  EDIT_PATIENT_BI_RADS_DATA,
} from '../../store/modules/patients.module';
import dropdown from '../atoms/dropdownSimple.vue';
import InputCustom from "../atoms/input";
import {
  BiopsyResultEnum, BiRadsEnum,
  MenstrualCycleEnum, PainInTheBreastEnum,
  PalpableMassInTheBreastEnum,
  PatientAgeEnum, TumorLocationEnum, TumorSideEnum, TumorSizeEnum
} from '../../core/helpers/variables/constants';
import {DELETE_PHOTO} from "../../store/modules/photos.module";

export default {
  name: 'universalModal',
  components: {
    InputCustom,
    sbmtBtn,
    simpleInput,
    dropdown,
  },
  props: {
    typeModal: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      typeMod: this.typeModal,
      buttonDisableMode: true,
      error: {
        editId: {
          condition: true,
        },
      },
      age: this.getArrayFromEnum(PatientAgeEnum),
      palpable: this.getArrayFromEnum(PalpableMassInTheBreastEnum),
      pain: this.getArrayFromEnum(PainInTheBreastEnum),
      biopsyResult: this.getArrayFromEnum(BiopsyResultEnum),
      tumorSide: this.getArrayFromEnum(TumorSideEnum),
      tumorSize: this.getArrayFromEnum(TumorSizeEnum),
      tumorLocation: this.getArrayFromEnum(TumorLocationEnum),
      biRads: this.getBiRadsArray(),
      menstrualCycle: this.getArrayFromEnum(MenstrualCycleEnum),
      menstrualCycleEnum: MenstrualCycleEnum,
      menstrualCycleDays: this.patientVisitMedicalData?.MenstrualCycleDays,
      updatedData: {},
      patientUpdatedDataError: null
    };
  },
  watch: {
    typeModal(newVal) {
      this.typeMod = newVal;
      if (newVal === 'editRelated') {
        this.updatedData = {
          palpableMassInTheBreast: this.patientVisitMedicalData.PalpableMassInTheBreast,
          painInTheBreast: this.patientVisitMedicalData.PainInTheBreast,
          menstrualCycle: this.patientVisitMedicalData.MenstrualCycle
        };
      } else if (newVal === 'editBiopsy') {
        this.updatedData = {
          tumorSize: this.patientVisitMedicalData.TumorSize,
          biopsyResult: this.patientVisitMedicalData.BiopsyResult,
          tumorSite: this.patientVisitMedicalData.TumorSite,
          tumorLocation: this.patientVisitMedicalData.TumorLocation,
        }
      } else if (newVal === 'editBiRads') {
        this.updatedData = {
          biRadsLeft: this.patientVisitMedicalData.BiRadsLeft,
          biRadsRight: this.patientVisitMedicalData.BiRadsRight
        }
      }
      this.updatedData.patientVisitId = this.patientVisitMedicalData.PatientVisitId;
    },
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters(
      {
        modalData: 'getModal',
        patientVisitMedicalData: 'getPatientVisitDetail',
        initialData: 'getPatientInitialData',
      },
    ),
    buttonComputedState() {
      return this.buttonDisableMode;
    },
    condition() {
      return this.modalData?.current;
    },
    modaltype() {
      return this.typeMod;
    },
    menstrualCycleComp() {
      const menstrualCycle = this.patientVisitMedicalData?.MenstrualCycle !== null
        ? this.menstrualCycle.find((obj) => {
          return obj.value === this.patientVisitMedicalData.MenstrualCycle
        }) : this.menstrualCycle[0];
      return [menstrualCycle];
    },
    PainComp() {
      const pain = this.patientVisitMedicalData?.PainInTheBreast !== null
        ? this.pain.find((obj) => {
          return obj.value === this.patientVisitMedicalData.PainInTheBreast
        }) : this.pain[0];
      return [pain];
    },
    PalpableComp() {
      const palpable = this.patientVisitMedicalData?.PalpableMassInTheBreast !== null
        ? this.palpable.find((obj) => {
          return obj.value === this.patientVisitMedicalData.PalpableMassInTheBreast
        }) : this.palpable[0];

      return palpable ? [palpable] : null;
    },
    BiopsyComp() {
      const biopsy = this.patientVisitMedicalData?.BiopsyResult !== null
        ? this.biopsyResult.find((obj) => {
          return obj.value === this.patientVisitMedicalData.BiopsyResult
        }) : this.biopsyResult[0];

      return [biopsy];
    },
    TumorSizeComp() {
      const valueSize = this.patientVisitMedicalData?.TumorSize !== null
        ? this.tumorSize.find((obj) => {
          return obj.value === this.patientVisitMedicalData.TumorSize
        }) : this.tumorSize[0];

      return [valueSize];
    },
    TumorSiteComp() {
      const valueSite = this.patientVisitMedicalData?.TumorSite !== null
        ? this.tumorSide.find((obj) => {
          return obj.value === this.patientVisitMedicalData.TumorSite
        }) : this.tumorSide[0];

      return valueSite ? [valueSite] : null;
    },
    TumorLocationComp() {
      const valueLocation = this.patientVisitMedicalData?.TumorLocation !== null
        ? this.tumorLocation.find((obj) => {
          return obj.value === this.patientVisitMedicalData.TumorLocation
        }) : this.tumorLocation[0];
      return [valueLocation];
    },
    BiRadsLeftComp() {
      const value = this.patientVisitMedicalData?.BiRadsLeft == null
        ? this.biRads[0] : this.biRads.find((obj) => {
          return obj.value === this.patientVisitMedicalData.BiRadsLeft
        });
      return [value];
    },
    BiRadsRightComp() {
      const value = this.patientVisitMedicalData?.BiRadsRight == null
        ? this.biRads[0] : this.biRads.find((obj) => {
          return obj.value === this.patientVisitMedicalData.BiRadsRight
        });
      return [value];
    },
  },
  methods: {
    buttonCondition(value) {
      this.buttonDisableMode = value;
    },
    selectValue(propName, value) {
      this.buttonCondition(false);
      this.updatedData[propName] = value?.value
    },
    async closeModal() {
      this.$emit('emitModuleCondition');
      await this.$store.dispatch(REMOVE_MODAL_ACTION);
      this.updatedData = {};
      this.buttonCondition(true);
    },
    async submitModal(value) {
      if (value === 'editid') {
        await this.$refs.observerEditForm.validate().then(() => {
          this.sendRequest(value)
        });
      } else if (value === 'deleteDocument') {
        await this.deleteDocument(this.modalData.params);
        await this.$store.dispatch(REMOVE_MODAL_ACTION);
        this.buttonCondition(true);
      } else {
        await this.sendRequest(value)
        this.buttonCondition(true);
      }
    },
    async sendRequest(data) {
      if (data === 'editid') {
        this.$store.dispatch(EDIT_PATIENT_ID, {
          patientId: this.initialData.PatientInfo.Id,
          newClinicAccountId: this.updatedData.ClinicAccountId,
        }).then(() => {
          this.closeModal();
        }).catch((ex) => {
          this.patientUpdatedDataError = ex;
        });
      } else if (data === 'deletePatient') {
        await this.$store.dispatch(DELETE_PATIENT, {
          patientId: this.patientSpecialId,
        });
        await this.closeModal();
        setTimeout(() => {
          this.$router.push({name: 'doctorPatientpage'});
        });
      } else if (data === 'editBiopsy') {
        await this.$store.dispatch(REQUEST_BIOPSY_DATA_CHANGE, this.updatedData);
        await this.closeModal();
      } else if (data === 'submitRelated') {
        await this.$store.dispatch(EDIT_ADDITIONAL_DATA_CHANGE, this.updatedData)
        await this.closeModal();
      } else if (data === 'editBiRads') {
        await this.$store.dispatch(EDIT_PATIENT_BI_RADS_DATA, this.updatedData)
        await this.closeModal();
      } else if (data === 'setDateOfBirth') {
        this.setDateOfBirth({
          patientId: this.initialData.PatientInfo.Id,
          dateOfBirth: this.updatedData.dateOfBirth
        }).then(() => {
          this.closeModal();
        }).catch((e) => {
          this.patientUpdatedDataError = e;
          console.log('error', e);
        });
      } else if (data === 'delete') {
        this.$store.dispatch(DELETE_PHOTO, {
          id: this.photoIdObject,
        }).then(() => {
          this.$store.dispatch(REMOVE_MODAL_ACTION).then(() => {
            this.$router.push({
              name: 'doctorPatientpageDetail',
              params: {patientId: this.$route.params.patientId}
            });
          })
        });
      }
      this.updatedData = {};
    },
    getArrayFromEnum(prop) {
      let arrayFromEnum = Object.values(prop);
      arrayFromEnum.shift();
      return arrayFromEnum.map(item => {
        return {...item, label: this.$t(item.label, this.currentSelectedLanguage)}
      });
    },
    getBiRadsArray() {
      let biRads = {...BiRadsEnum};
      delete biRads.all;
      biRads.notSelected = {
        value: null,
        label: this.$t(BiRadsEnum.notSelected.label, this.currentSelectedLanguage),
      }
      return Object.values(biRads);
    },
    patientClinicId(value) {
      this.updatedData.ClinicAccountId = value;
    },
    setLocalDateOfBirth(value) {
      this.updatedData.dateOfBirth = new Date(value);
    },
    ...mapActions({
      deleteDocument: 'deleteDocument',
      setDateOfBirth: 'setDateOfBirth'
    })
  },
};
</script>

<style lang="scss">
.special-input {
  .vs__actions {
    display: flex;
    top: 0;
  }
}

.universal-window {
  .modal-header-title {
    margin-bottom: 26px;

    h6 {
      margin-bottom: 0;
      @include font-source($openSans, 18px, $zeus-blue, 600, normal, 1.21px);
    }
  }

  .modal-body {
    margin: 0 0 13px 0;
    padding: 0;

    label {
      margin-bottom: 0;
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
      @include font-source($openSans, 12px, $apollon !important, normal, 1.21, 0.35px);
    }

    .th-drop-wrp {
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &:last-child {
        margin-bottom: 17px;
      }

      .th-drop-wrp--input {
        height: 45px !important;
        display: flex;
        justify-content: space-between;

        .vs__selected-options {
          display: flex;
        }
      }

      .vs__dropdown-toggle {
        width: 100%;
        border: none !important;
        box-sizing: border-box;
      }

      .vs__search {
        @include font-source($openSans, 14px, $apollon !important, normal, 1.21, 0.35px);
      }

      .vs__selected {
        display: block;
        @include font-source($openSans, 14px, $apollon !important, normal, 1.21, 0.35px);
      }
    }
  }

  .modal-footer {
    justify-content: center;

    & > button {
      width: 94px;
      text-transform: uppercase;
    }
  }
}

.deleted-window {
  margin-left: 43px;
  margin-right: 43px;

  .modal-header-title {
    margin-bottom: 25px;

    h6 {
      margin-bottom: 0;
      @include font-source($openSans, 18px, $zeus-blue, 600, normal, 1.21px);
    }
  }

  .th-input-wrapper-child:focus {
    border: 1px solid rgba(30, 45, 62, 0.1);
  }

  .modal-footer {
    & > button {
      width: 94px;
    }
  }
}
</style>

<style lang="scss" scoped>

.modal {
  &-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 45, 62, 0.2);
    transition: opacity .3s ease;
  }

  &-container {
    width: 348px;
    margin: 100px auto 0;
    padding: 26px 27px 30px 27px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  &-body {
    margin: 20px 0;
  }

  &-header {
    position: relative;
    padding: 0;
    border: none;
    height: 13px;

    & > svg {
      color: $zeus-blue;
      position: absolute;
      right: -17px;
      top: -17px;
      font-size: 40px;
      cursor: pointer;
    }
  }

  &-header-title {
    & > h6 {
      @include font-source($openSans, 18px, $zeus-blue, 600, normal, normal);
      text-align: center;
    }
  }

  &-footer {
    padding: 0;
    border: none;
    justify-content: space-between;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
